import { isScheduler } from '../util/isScheduler';
import { isArray } from '../util/isArray';
import { OuterSubscriber } from '../OuterSubscriber';
import { subscribeToResult } from '../util/subscribeToResult';
import { fromArray } from './fromArray';
const NONE = {};
export function combineLatest(...observables) {
  let resultSelector = null;
  let scheduler = null;
  if (isScheduler(observables[observables.length - 1])) {
    scheduler = observables.pop();
  }
  if (typeof observables[observables.length - 1] === 'function') {
    resultSelector = observables.pop();
  }
  if (observables.length === 1 && isArray(observables[0])) {
    observables = observables[0];
  }
  return fromArray(observables, scheduler).lift(new CombineLatestOperator(resultSelector));
}
export class CombineLatestOperator {
  constructor(resultSelector) {
    this.resultSelector = resultSelector;
  }
  call(subscriber, source) {
    return source.subscribe(new CombineLatestSubscriber(subscriber, this.resultSelector));
  }
}
export class CombineLatestSubscriber extends OuterSubscriber {
  constructor(destination, resultSelector) {
    super(destination);
    this.resultSelector = resultSelector;
    this.active = 0;
    this.values = [];
    this.observables = [];
  }
  _next(observable) {
    this.values.push(NONE);
    this.observables.push(observable);
  }
  _complete() {
    const observables = this.observables;
    const len = observables.length;
    if (len === 0) {
      this.destination.complete();
    } else {
      this.active = len;
      this.toRespond = len;
      for (let i = 0; i < len; i++) {
        const observable = observables[i];
        this.add(subscribeToResult(this, observable, observable, i));
      }
    }
  }
  notifyComplete(unused) {
    if ((this.active -= 1) === 0) {
      this.destination.complete();
    }
  }
  notifyNext(outerValue, innerValue, outerIndex, innerIndex, innerSub) {
    const values = this.values;
    const oldVal = values[outerIndex];
    const toRespond = !this.toRespond ? 0 : oldVal === NONE ? --this.toRespond : this.toRespond;
    values[outerIndex] = innerValue;
    if (toRespond === 0) {
      if (this.resultSelector) {
        this._tryResultSelector(values);
      } else {
        this.destination.next(values.slice());
      }
    }
  }
  _tryResultSelector(values) {
    let result;
    try {
      result = this.resultSelector.apply(this, values);
    } catch (err) {
      this.destination.error(err);
      return;
    }
    this.destination.next(result);
  }
}
