import { OuterSubscriber } from '../OuterSubscriber';
import { subscribeToResult } from '../util/subscribeToResult';
export const defaultThrottleConfig = {
  leading: true,
  trailing: false
};
export function throttle(durationSelector, config = defaultThrottleConfig) {
  return source => source.lift(new ThrottleOperator(durationSelector, config.leading, config.trailing));
}
class ThrottleOperator {
  constructor(durationSelector, leading, trailing) {
    this.durationSelector = durationSelector;
    this.leading = leading;
    this.trailing = trailing;
  }
  call(subscriber, source) {
    return source.subscribe(new ThrottleSubscriber(subscriber, this.durationSelector, this.leading, this.trailing));
  }
}
class ThrottleSubscriber extends OuterSubscriber {
  constructor(destination, durationSelector, _leading, _trailing) {
    super(destination);
    this.destination = destination;
    this.durationSelector = durationSelector;
    this._leading = _leading;
    this._trailing = _trailing;
    this._hasValue = false;
  }
  _next(value) {
    this._hasValue = true;
    this._sendValue = value;
    if (!this._throttled) {
      if (this._leading) {
        this.send();
      } else {
        this.throttle(value);
      }
    }
  }
  send() {
    const {
      _hasValue,
      _sendValue
    } = this;
    if (_hasValue) {
      this.destination.next(_sendValue);
      this.throttle(_sendValue);
    }
    this._hasValue = false;
    this._sendValue = null;
  }
  throttle(value) {
    const duration = this.tryDurationSelector(value);
    if (!!duration) {
      this.add(this._throttled = subscribeToResult(this, duration));
    }
  }
  tryDurationSelector(value) {
    try {
      return this.durationSelector(value);
    } catch (err) {
      this.destination.error(err);
      return null;
    }
  }
  throttlingDone() {
    const {
      _throttled,
      _trailing
    } = this;
    if (_throttled) {
      _throttled.unsubscribe();
    }
    this._throttled = null;
    if (_trailing) {
      this.send();
    }
  }
  notifyNext(outerValue, innerValue, outerIndex, innerIndex, innerSub) {
    this.throttlingDone();
  }
  notifyComplete() {
    this.throttlingDone();
  }
}
