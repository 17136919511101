import { OuterSubscriber } from '../OuterSubscriber';
import { subscribeToResult } from '../util/subscribeToResult';
export function takeUntil(notifier) {
  return source => source.lift(new TakeUntilOperator(notifier));
}
class TakeUntilOperator {
  constructor(notifier) {
    this.notifier = notifier;
  }
  call(subscriber, source) {
    const takeUntilSubscriber = new TakeUntilSubscriber(subscriber);
    const notifierSubscription = subscribeToResult(takeUntilSubscriber, this.notifier);
    if (notifierSubscription && !takeUntilSubscriber.seenValue) {
      takeUntilSubscriber.add(notifierSubscription);
      return source.subscribe(takeUntilSubscriber);
    }
    return takeUntilSubscriber;
  }
}
class TakeUntilSubscriber extends OuterSubscriber {
  constructor(destination) {
    super(destination);
    this.seenValue = false;
  }
  notifyNext(outerValue, innerValue, outerIndex, innerIndex, innerSub) {
    this.seenValue = true;
    this.complete();
  }
  notifyComplete() {}
}
