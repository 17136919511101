import { OuterSubscriber } from '../OuterSubscriber';
import { subscribeToResult } from '../util/subscribeToResult';
export function audit(durationSelector) {
  return function auditOperatorFunction(source) {
    return source.lift(new AuditOperator(durationSelector));
  };
}
class AuditOperator {
  constructor(durationSelector) {
    this.durationSelector = durationSelector;
  }
  call(subscriber, source) {
    return source.subscribe(new AuditSubscriber(subscriber, this.durationSelector));
  }
}
class AuditSubscriber extends OuterSubscriber {
  constructor(destination, durationSelector) {
    super(destination);
    this.durationSelector = durationSelector;
    this.hasValue = false;
  }
  _next(value) {
    this.value = value;
    this.hasValue = true;
    if (!this.throttled) {
      let duration;
      try {
        const {
          durationSelector
        } = this;
        duration = durationSelector(value);
      } catch (err) {
        return this.destination.error(err);
      }
      const innerSubscription = subscribeToResult(this, duration);
      if (!innerSubscription || innerSubscription.closed) {
        this.clearThrottle();
      } else {
        this.add(this.throttled = innerSubscription);
      }
    }
  }
  clearThrottle() {
    const {
      value,
      hasValue,
      throttled
    } = this;
    if (throttled) {
      this.remove(throttled);
      this.throttled = null;
      throttled.unsubscribe();
    }
    if (hasValue) {
      this.value = null;
      this.hasValue = false;
      this.destination.next(value);
    }
  }
  notifyNext(outerValue, innerValue, outerIndex, innerIndex) {
    this.clearThrottle();
  }
  notifyComplete() {
    this.clearThrottle();
  }
}
