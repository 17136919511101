import { ReplaySubject } from '../ReplaySubject';
export function shareReplay(configOrBufferSize, windowTime, scheduler) {
  let config;
  if (configOrBufferSize && typeof configOrBufferSize === 'object') {
    config = configOrBufferSize;
  } else {
    config = {
      bufferSize: configOrBufferSize,
      windowTime,
      refCount: false,
      scheduler
    };
  }
  return source => source.lift(shareReplayOperator(config));
}
function shareReplayOperator({
  bufferSize = Number.POSITIVE_INFINITY,
  windowTime = Number.POSITIVE_INFINITY,
  refCount: useRefCount,
  scheduler
}) {
  let subject;
  let refCount = 0;
  let subscription;
  let hasError = false;
  let isComplete = false;
  return function shareReplayOperation(source) {
    refCount++;
    if (!subject || hasError) {
      hasError = false;
      subject = new ReplaySubject(bufferSize, windowTime, scheduler);
      subscription = source.subscribe({
        next(value) {
          subject.next(value);
        },
        error(err) {
          hasError = true;
          subject.error(err);
        },
        complete() {
          isComplete = true;
          subscription = undefined;
          subject.complete();
        }
      });
    }
    const innerSub = subject.subscribe(this);
    this.add(() => {
      refCount--;
      innerSub.unsubscribe();
      if (subscription && !isComplete && useRefCount && refCount === 0) {
        subscription.unsubscribe();
        subscription = undefined;
        subject = undefined;
      }
    });
  };
}
