import { OuterSubscriber } from '../OuterSubscriber';
import { InnerSubscriber } from '../InnerSubscriber';
import { subscribeToResult } from '../util/subscribeToResult';
import { map } from './map';
import { from } from '../observable/from';
export function exhaustMap(project, resultSelector) {
  if (resultSelector) {
    return source => source.pipe(exhaustMap((a, i) => from(project(a, i)).pipe(map((b, ii) => resultSelector(a, b, i, ii)))));
  }
  return source => source.lift(new ExhaustMapOperator(project));
}
class ExhaustMapOperator {
  constructor(project) {
    this.project = project;
  }
  call(subscriber, source) {
    return source.subscribe(new ExhaustMapSubscriber(subscriber, this.project));
  }
}
class ExhaustMapSubscriber extends OuterSubscriber {
  constructor(destination, project) {
    super(destination);
    this.project = project;
    this.hasSubscription = false;
    this.hasCompleted = false;
    this.index = 0;
  }
  _next(value) {
    if (!this.hasSubscription) {
      this.tryNext(value);
    }
  }
  tryNext(value) {
    let result;
    const index = this.index++;
    try {
      result = this.project(value, index);
    } catch (err) {
      this.destination.error(err);
      return;
    }
    this.hasSubscription = true;
    this._innerSub(result, value, index);
  }
  _innerSub(result, value, index) {
    const innerSubscriber = new InnerSubscriber(this, value, index);
    const destination = this.destination;
    destination.add(innerSubscriber);
    const innerSubscription = subscribeToResult(this, result, undefined, undefined, innerSubscriber);
    if (innerSubscription !== innerSubscriber) {
      destination.add(innerSubscription);
    }
  }
  _complete() {
    this.hasCompleted = true;
    if (!this.hasSubscription) {
      this.destination.complete();
    }
    this.unsubscribe();
  }
  notifyNext(outerValue, innerValue, outerIndex, innerIndex, innerSub) {
    this.destination.next(innerValue);
  }
  notifyError(err) {
    this.destination.error(err);
  }
  notifyComplete(innerSub) {
    const destination = this.destination;
    destination.remove(innerSub);
    this.hasSubscription = false;
    if (this.hasCompleted) {
      this.destination.complete();
    }
  }
}
